var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isLoading && !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1250px" } },
          [
            _vm._m(0),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "exchangeBox" }, [
                _c("img", {
                  staticClass: "exchangeBg",
                  attrs: {
                    src: require("../../assets/imgs/activity/20240101/bg_pc_exchange.png"),
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "list d-flex a-center j-sb" },
                  _vm._l(_vm.giftList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "item d-flex flex-column a-center j-center",
                      },
                      [
                        _c("div", { staticClass: "imgBox" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: item.iconUrl, alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c(
                          "div",
                          {
                            class: [
                              "btn",
                              { active: item.haveNum >= item.receiveNum },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.receiveHandle(item)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "img",
                              attrs: { src: item.btnUrl, alt: "" },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "descText" }, [
                  _vm._v(" 您目前拥有"),
                  _c("span", [_vm._v(_vm._s(_vm.giftList[0].haveNum))]),
                  _vm._v("个龙鳞/"),
                  _c("span", [_vm._v(_vm._s(_vm.giftList[1].haveNum))]),
                  _vm._v("个龙角/"),
                  _c("span", [_vm._v(_vm._s(_vm.giftList[2].haveNum))]),
                  _vm._v("个龙须 "),
                ]),
              ]),
              _vm._m(1),
              _vm._m(2),
            ]),
            _vm.isShowReceivePop
              ? _c("div", { staticClass: "receivePop" }, [
                  _c("div", { staticClass: "mask" }),
                  _c("div", { staticClass: "popBox" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.receivedText)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "btnBox d-flex a-center j-center" },
                      [
                        _c("div", {
                          staticClass: "btn cancel",
                          on: { click: _vm.cancelHandle },
                        }),
                        _c("div", {
                          staticClass: "btn confirm",
                          on: { click: _vm.confirmHandle },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    !_vm.isLoading && _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/20240101/bg_h5_top.jpg"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "iconFlower",
            attrs: {
              src: require("../../assets/imgs/activity/20240101/icon_h5_flower.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "giveCard",
            attrs: {
              src: require("../../assets/imgs/activity/20240101/bg_h5_giveCard.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "exchangeBox" }, [
              _c("img", {
                staticClass: "exchangeBg",
                attrs: {
                  src: require("../../assets/imgs/activity/20240101/bg_h5_exchange.png"),
                  alt: "",
                },
              }),
              _c(
                "div",
                { staticClass: "list d-flex flex-column a-center" },
                _vm._l(_vm.giftList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item d-flex flex-column a-center j-center",
                    },
                    [
                      _c("div", { staticClass: "imgBox" }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: item.iconUrl, alt: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c(
                        "div",
                        {
                          class: [
                            "btn",
                            { active: item.haveNum >= item.receiveNum },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.receiveHandle(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: item.btnUrl, alt: "" },
                          }),
                        ]
                      ),
                      _c("div", { staticClass: "descText" }, [
                        _vm._v(" 已拥有"),
                        _c("span", [_vm._v(_vm._s(item.haveNum))]),
                        _vm._v(
                          "个" +
                            _vm._s(
                              item.type == 1
                                ? "龙鳞"
                                : item.type == 2
                                ? "龙角"
                                : "龙须"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._m(3),
            _vm._m(4),
          ]),
          _vm.isShowReceivePop
            ? _c("div", { staticClass: "receivePop" }, [
                _c("div", { staticClass: "mask" }),
                _c("div", { staticClass: "popBox" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.receivedText)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "btnBox d-flex a-center j-center" },
                    [
                      _c("div", {
                        staticClass: "btn cancel",
                        on: { click: _vm.cancelHandle },
                      }),
                      _c("div", {
                        staticClass: "btn confirm",
                        on: { click: _vm.confirmHandle },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bgBox" }, [
      _c("img", {
        staticClass: "bg_activity",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_pc_top.jpg"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "iconFlower",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/icon_pc_flower.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "giveCard",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_pc_giveCard.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleBg",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_pc_rules.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _c("img", {
        staticClass: "rulesDescBg",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_pc_rulesDesc.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleBg",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_h5_rules.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _c("img", {
        staticClass: "rulesDescBg",
        attrs: {
          src: require("../../assets/imgs/activity/20240101/bg_h5_rulesDesc.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }