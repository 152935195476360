<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1250px;" v-if="!isLoading && !isMobile">
            <div class="bgBox">
                <img class="bg_activity" src="../../assets/imgs/activity/20240101/bg_pc_top.jpg" alt="">
                <img src="../../assets/imgs/activity/20240101/icon_pc_flower.png" alt="" class="iconFlower">
                <img src="../../assets/imgs/activity/20240101/bg_pc_giveCard.png" alt="" class="giveCard">
            </div>
            <div class="content">
                <div class="exchangeBox">
                    <img src="../../assets/imgs/activity/20240101/bg_pc_exchange.png" alt="" class="exchangeBg">
                    <div class="list d-flex a-center j-sb">
                        <div class="item d-flex flex-column a-center j-center" v-for="(item, index) in giftList"
                            :key="index">
                            <div class="imgBox">
                                <img :src="item.iconUrl" alt="" class="img">
                            </div>
                            <div class="text">{{ item.name }}</div>
                            <div :class="['btn', { 'active': item.haveNum >= item.receiveNum }]"
                                @click="receiveHandle(item)">
                                <img :src="item.btnUrl" alt="" class="img">
                            </div>
                        </div>
                    </div>
                    <div class="descText">
                        您目前拥有<span>{{ giftList[0].haveNum }}</span>个龙鳞/<span>{{ giftList[1].haveNum }}</span>个龙角/<span>{{
                            giftList[2].haveNum }}</span>个龙须
                    </div>
                </div>
                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20240101/bg_pc_rules.png" alt="" class="ruleBg">
                </div>

                <div class="descBox">
                    <img src="../../assets/imgs/activity/20240101/bg_pc_rulesDesc.png" alt="" class="rulesDescBg">
                </div>
            </div>

            <div class="receivePop" v-if="isShowReceivePop">
                <div class="mask"></div>
                <div class="popBox">
                    <div class="text">{{ receivedText }}</div>
                    <div class="btnBox d-flex a-center j-center">
                        <div class="btn cancel" @click="cancelHandle"></div>
                        <div class="btn confirm" @click="confirmHandle"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20240101/bg_h5_top.jpg" alt="">
            <img src="../../assets/imgs/activity/20240101/icon_h5_flower.png" alt="" class="iconFlower">
            <img src="../../assets/imgs/activity/20240101/bg_h5_giveCard.png" alt="" class="giveCard">
            <div class="content">
                <div class="exchangeBox">
                    <img src="../../assets/imgs/activity/20240101/bg_h5_exchange.png" alt="" class="exchangeBg">
                    <div class="list d-flex flex-column a-center">
                        <div class="item d-flex flex-column a-center j-center" v-for="(item, index) in giftList"
                            :key="index">
                            <div class="imgBox">
                                <img :src="item.iconUrl" alt="" class="img">
                            </div>
                            <div class="text">{{ item.name }}</div>
                            <div :class="['btn', { 'active': item.haveNum >= item.receiveNum }]"
                                @click="receiveHandle(item)">
                                <img :src="item.btnUrl" alt="" class="img">
                            </div>
                            <div class="descText">
                                已拥有<span>{{ item.haveNum }}</span>个{{ item.type == 1 ? '龙鳞' : item.type == 2 ? '龙角' : '龙须'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20240101/bg_h5_rules.png" alt="" class="ruleBg">
                </div>

                <div class="descBox">
                    <img src="../../assets/imgs/activity/20240101/bg_h5_rulesDesc.png" alt="" class="rulesDescBg">
                </div>
            </div>

            <div class="receivePop" v-if="isShowReceivePop">
                <div class="mask"></div>
                <div class="popBox">
                    <div class="text">{{ receivedText }}</div>
                    <div class="btnBox d-flex a-center j-center">
                        <div class="btn cancel" @click="cancelHandle"></div>
                        <div class="btn confirm" @click="confirmHandle"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    getGiftInfoForNewYear,
    receiveGiftForNewYear,
} from "@/util/api/api.js"
export default {
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            giftList: [
                {
                    id: 1,
                    type: 1,
                    iconUrl: require('../../assets/imgs/activity/20240101/icon_gift1.png'),
                    btnUrl: require('../../assets/imgs/activity/20240101/icon_btn_ll.png'),
                    name: '限定座驾 龙年机车（1天）',
                    receivedText: '5个龙鳞兑换限定座驾 龙年机车，\n是否继续？',
                    haveNum: 0,
                    receiveNum: 5
                },
                {
                    id: 2,
                    type: 2,
                    iconUrl: require('../../assets/imgs/activity/20240101/icon_gift2.png'),
                    btnUrl: require('../../assets/imgs/activity/20240101/icon_btn_lj.png'),
                    name: '再见2023',
                    receivedText: '9个龙角兑换 再见2023，\n是否继续？',
                    haveNum: 0,
                    receiveNum: 9
                },
                {
                    id: 3,
                    type: 3,
                    iconUrl: require('../../assets/imgs/activity/20240101/icon_gift3.png'),
                    btnUrl: require('../../assets/imgs/activity/20240101/icon_btn_lx.png'),
                    name: '携手2024',
                    receivedText: '90个龙须兑换 携手2024，\n是否继续？',
                    haveNum: 0,
                    receiveNum: 90
                },
            ],// 礼物列表
            isShowReceivePop: false,
            receiveType: null,
            receivedText: '',// 兑换弹窗文案
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._getGiftInfoForNewYear()
    },
    methods: {
        // 获取元旦节活动信息
        async _getGiftInfoForNewYear() {
            try {
                let res = await getGiftInfoForNewYear()
                if (res.resultCode == '000000') {
                    this.giftList[0].haveNum = res.data.dragonScale || 0
                    this.giftList[1].haveNum = res.data.dragonHorn || 0
                    this.giftList[2].haveNum = res.data.dragonBeard || 0
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        },

        // 打开确认兑换弹窗
        receiveHandle(item) {
            // 不满足兑换条件
            if (item.haveNum < item.receiveNum) {
                return
            }
            this.receivedText = item.receivedText
            this.receiveType = item.type
            this.isShowReceivePop = true
        },

        // 取消兑换
        cancelHandle() {
            this.isShowReceivePop = false
        },

        // 确认兑换
        async confirmHandle() {
            if (this.receiveing) {
                return
            }
            this.receiveing = true
            try {
                let res = await receiveGiftForNewYear({ receiveType: this.receiveType })
                if (res.resultCode == '000000') {
                    this.$message.success('兑换成功')
                    this._getGiftInfoForNewYear()
                }
                this.receiveing = false
            } catch (error) {
                console.log(error)
                this.receiveing = false
            }
            this.isShowReceivePop = false
        },
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #F8A9C0;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bgBox {
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;

            .bg_activity {
                height: 1120px;
                width: 1920px;
                display: block;
                // width: 100%;
                z-index: 1;
            }

            .iconFlower {
                height: 523px;
                width: 623px;
                display: block;
                position: absolute;
                left: 0;
                top: 792px;
                z-index: 2;
            }

            .giveCard {
                height: 746px;
                width: 1083px;
                position: absolute;
                top: 944px;
                left: 50%;
                display: block;
                margin-left: -530px;
            }
        }

        .content {
            position: relative;
            z-index: 1;

            .exchangeBox {
                height: 679px;
                width: 1200px;
                position: relative;
                margin: 1762px auto 0;
                padding: 1px;
                box-sizing: border-box;

                .exchangeBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                }

                .list {
                    position: relative;
                    padding: 0 80px;
                    margin-top: 103px;

                    .item {
                        width: 300px;
                        box-sizing: border-box;

                        .imgBox {
                            width: 200px;
                            height: 200px;
                            overflow: hidden;

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .text {
                            font-size: 24px;
                            font-family: HYShuYuanHeiJ;
                            font-weight: 500;
                            color: #4E3A40;
                            line-height: 26px;
                            text-align: center;
                            margin-top: 22px;
                        }

                        .btn {
                            z-index: 1;
                            height: 102px;
                            width: 300px;
                            cursor: pointer;
                            opacity: .4;
                            margin-top: 40px;

                            &.active {
                                opacity: 1;
                            }

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }
                    }
                }

                .descText {
                    position: relative;
                    font-size: 24px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #4E3A40;
                    margin-top: 54px;
                    text-align: center;

                    span {
                        color: #CE6D44;
                        margin: 0 6px;
                        font-size: 32px;
                    }
                }
            }

            .ruleBox {
                padding-top: 72px;
                height: 497px;
                width: 1200px;
                position: relative;
                margin: 0 auto;

                .ruleBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }

            .descBox {
                margin: 0 auto;
                padding-top: 72px;
                padding-bottom: 107px;
                height: 611px;
                width: 1197px;
                position: relative;

                .rulesDescBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .receivePop {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            min-width: 1250px;
            width: 100vw;
            margin: 0 auto;
            z-index: 1;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            .popBox {
                animation: showCenterPop ease .3s;
                width: 680px;
                height: 290px;
                background: #D75E82;
                box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
                border-radius: 48px;

                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -340px;
                margin-top: -160px;
                z-index: 1;

                .text {
                    width: 430px;
                    font-size: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 48px;
                    margin: 62px auto 0;
                    text-align: center;
                    white-space: pre-line;
                }

                .btnBox {
                    margin: 35px auto 0;

                    .btn {
                        height: 62px;
                        width: 215px;
                        cursor: pointer;

                        &.cancel {
                            background: url(../../assets/imgs/activity/20240101/icon_pc_btn_cancel.png)no-repeat center/cover;
                            margin-right: 55px;
                        }

                        &.confirm {
                            background: url(../../assets/imgs/activity/20240101/icon_pc_btn_confirm.png)no-repeat center/cover;
                        }
                    }
                }

            }
        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;
        background: #F8A9C0;

        .bg_activity {
            width: 100vw;
            display: block;
        }

        .iconFlower {
            height: 33.867vw;
            width: 40.533vw;
            display: block;
            position: absolute;
            left: 0;
            top: 64.8vw;
            z-index: 1;
        }

        .giveCard {
            height: 128.267vw;
            width: 96.267vw;
            display: block;
            margin-left: 3.467vw;
            margin-top: -13.067vw;
        }

        .content {
            position: relative;
            z-index: 1;

            .exchangeBox {
                height: 293.333vw;
                width: 93.333vw;
                position: relative;
                margin: 8.533vw auto 0;
                padding: 1px;
                box-sizing: border-box;

                .exchangeBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                }

                .list {
                    position: relative;
                    margin-top: 10.933vw;

                    .item {
                        margin-bottom: 12.8vw;
                        box-sizing: border-box;

                        .imgBox {
                            width: 42.667vw;
                            height: 42.667vw;
                            overflow: hidden;

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .text {
                            font-size: 4.267vw;
                            font-family: HYShuYuanHeiJ;
                            font-weight: 500;
                            color: #4E3A40;
                            line-height: 4.8vw;
                            text-align: center;
                            margin-top: 3.2vw;
                        }

                        .btn {
                            height: 18.133vw;
                            width: 53.333vw;
                            cursor: pointer;
                            opacity: .4;
                            margin-top: 3.2vw;
                            -webkit-tap-highlight-color: transparent;

                            &.active {
                                opacity: 1;
                            }

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                            }
                        }

                        .descText {
                            position: relative;
                            font-size: 3.733vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            color: #4E3A40;
                            margin-top: 2.133vw;
                            text-align: center;

                            span {
                                color: #CE6D44;
                                margin: 0 0.8vw;
                                font-size: 4vw;
                            }
                        }
                    }
                }
            }

            .ruleBox {
                padding-top: 8.533vw;
                height: 82.133vw;
                width: 93.333vw;
                position: relative;
                margin: 0 auto;

                .ruleBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }

            .descBox {
                margin: 0 auto;
                padding-top: 8.533vw;
                padding-bottom: 9.867vw;
                height: 153.867vw;
                width: 90.133vw;
                position: relative;

                .rulesDescBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .receivePop {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            margin: 0 auto;
            z-index: 1;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 100%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            .popBox {
                animation: showCenterPop ease .3s;
                width: 79.467vw;
                height: 40.267vw;
                background: #D75E82;
                box-shadow: inset 0px 0px 2.667vw 0px rgba(255, 255, 255, 0.5);
                border-radius: 6.4vw;

                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -39.733vw;
                margin-top: -20.133vw;
                z-index: 1;

                .text {
                    width: 58vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 5.333vw;
                    margin: 7.733vw auto;
                    text-align: center;
                    white-space: pre-line;
                }

                .btnBox {
                    margin: 0 auto;

                    .btn {
                        height: 9.333vw;
                        width: 27.467vw;
                        cursor: pointer;
                        -webkit-tap-highlight-color: transparent;
                        /* 高亮颜色设置为透明 */

                        &.cancel {
                            background: url(../../assets/imgs/activity/20240101/icon_h5_btn_cancel.png)no-repeat center/cover;
                            margin-right: 6.133vw;
                        }

                        &.confirm {
                            background: url(../../assets/imgs/activity/20240101/icon_h5_btn_confirm.png)no-repeat center/cover;
                        }
                    }
                }

            }
        }
    }
}
</style>
